// main.js
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import createRouter from './router';
import BootstrapVue3 from 'bootstrap-vue-3';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
const supportedLanguages = [
    'en', 'zh', 'es', 'fr', 'de', 'ja', 'ko', 'zh_Hant', 'pt', 'it', 'ru', 'tr', 'pl', 'nl', 'vi'
];

function getBrowserLanguage() {
    let browserLang;
    if (navigator.languages && navigator.languages.length) {
        browserLang = navigator.languages[0];
    } else {
        browserLang = navigator.language || navigator.userLanguage;
    }
    const shortLang = browserLang.split('-')[0];
    return supportedLanguages.includes(shortLang) ? shortLang : 'en';
}

const savedLang = localStorage.getItem('userLanguage') || getBrowserLanguage();

const i18n = createI18n({
    legacy: false,
    locale: savedLang,
    fallbackLocale: 'en',
    messages: {}
});

// Load only the current language
loadLanguageAsync(savedLang);

const router = createRouter(i18n);

const app = createApp(App);
app.use(BootstrapVue3);
app.use(router);
app.use(i18n);
app.mount('#app');

// Function to load language file asynchronously
export async function loadLanguageAsync(lang) {
    if (!i18n.global.availableLocales.includes(lang)) {
        const messages = await import(`./locales/${lang}.json`);
        i18n.global.setLocaleMessage(lang, messages.default);
    }

    i18n.global.locale.value = lang;
    document.querySelector('html').setAttribute('lang', lang);
    localStorage.setItem('userLanguage', lang);
}