// router.js

import { createRouter, createWebHistory } from 'vue-router';

const components = {
    UrlShortener: () => import('@/components/UrlShortener.vue'),
    UrlRedirect: () => import('@/components/UrlRedirect.vue'),
    UrlManage: () => import('@/components/UrlManage.vue'),
    UrlReport: () => import('@/components/UrlReport.vue'),
    TermsOfService: () => import('@/components/TermsOfService.vue'),
    UserPage: () => import('@/components/UserPage.vue'),
    AuthPage: () => import('@/components/AuthPage.vue'),
    GoogleAuthCallback: () => import('@/components/GoogleAuthCallback.vue'),
    PrivacyPolicy: () => import('@/components/PrivacyPolicy.vue'),
};

const routes = [
    { path: '/', name: 'Home', component: components.UrlShortener, meta: { titleKey: 'routes.home.title', descriptionKey: 'routes.home.description' } },
    { path: '/manage', name: 'manage', component: components.UrlManage, meta: { titleKey: 'routes.manage.title' } },
    { path: '/report', name: 'report', component: components.UrlReport, meta: { titleKey: 'routes.report.title' } },
    { path: '/terms', name: 'terms', component: components.TermsOfService, meta: { titleKey: 'routes.terms.title' } },
    { path: '/user', name: 'user', component: components.UserPage, meta: { titleKey: 'routes.user.title' } },
    { path: '/auth/google/callback', name: 'GoogleAuthCallback', component: components.GoogleAuthCallback, meta: { titleKey: 'routes.googleAuthCallback.title' }},
    { path: '/auth', name: 'auth', component: components.AuthPage, meta: { titleKey: 'routes.auth.title' } },
    { path: '/privacy', name: 'privacy', component: components.PrivacyPolicy, meta: { titleKey: 'routes.privacy.title' } },
    {
        path: '/:path',
        name: 'Redirect',
        component: components.UrlRedirect,
        beforeEnter: (to, from, next) => {
            /^[A-Za-z0-9_-]{4,64}$/.test(to.params.path) ? next() : next({ name: 'Home', query: { error: 'invalid-url' } });
        },
        meta: { titleKey: 'routes.redirect.title' },
    },
    { path: '/s/:id', name: 'ShortUrlRedirect', component: components.UrlRedirect }, // 捕获以前的内容，未来删除
];

const createRouterInstance = (i18n) => {
    const router = createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes,
    });

    router.beforeEach((to, from, next) => {
        if (typeof window !== 'undefined') {
            const { hostname, protocol } = window.location;
            if (hostname.startsWith('www.')) {
                window.location.href = `${protocol}//${hostname.replace('www.', '')}${to.fullPath}`;
                return;
            }
        }
        next();
    });

    router.afterEach((to) => {
        document.title = i18n.global.t(to.meta.titleKey) || 'MiniURL.com';
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', to.meta.descriptionKey ? i18n.global.t(to.meta.descriptionKey) : '');
        }
    });

    return router;
};

export default createRouterInstance;